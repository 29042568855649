<template>
  <div class="w-full d-flex align-center justify-center main_container">
    <!-- Tarjeta contenedora -->
    <v-card
      class="cardContainer pa-3 rounded-xl mx-3 d-flex flex-column justify-center"
    >
      <div class="pl-5 pr-5">
        <!-- Título de la tarjeta -->
        <v-card-title class="d-flex justify-center mb-4 flex-column">
             <v-avatar tile size="150">
            <v-img contain src="/clivenlogo.svg"></v-img>
          </v-avatar>
          <h1 class="d-flex justify-center">
            {{ getNameClinica }}
          </h1></v-card-title
        >
        <v-card-subtitle class="mt-3 mb-5 d-flex justify-center"
          >Ingrese con su cuenta de usuario de la clínica</v-card-subtitle
        >
        <!-- Formulario de ingreso a la clínica -->
        <v-card-text>
          <v-select
            outlined
            :items="sedes"
            v-model="sucursal"
            item-text="Origen"
            label="Seleccione sucursal"
            :rules="[rules.required]"
            class="mb-2"
            :validate-on-blur="false"
            :error="error"
            @keyup.enter="submit"
            @change="resetErrors"
          ></v-select>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-text-field
              v-model="email"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              label="Correo electrónico"
              name="email"
              class="mb-2"
              outlined
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              :error="error"
              :error-messages="errorMessages"
              label="Contraseña"
              name="password" 
              class="ma-0 pa-0"
              outlined
              @change="resetErrors"
              @keyup.enter="submit"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-alert
              v-if="errorLoginLicen"
              class="ma-0 pa-2"
              type="error"
              border="left"
              >Error de credenciailes</v-alert
            >
            <v-btn
              :loading="isLoading"
              :disabled="isSignInDisabled"
              block
              class="mt-5 text-capitalize rounded-lg"
              x-large
              color="primary"
              @click="submit()"
              >Ingresar</v-btn
            >
          </v-form>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import router from "../../router";

export default {
  data() {
    return {
      // Botones de ingreso
      isLoading: false,
      isSignInDisabled: false,

      // Formulario
      isFormValid: true,
      email: "",
      password: "",
      sucursal: "",

      // Error de ingreso
      error: false,
      errorMessages: "",

      errorProvider: false,
      errorProviderMessages: "",

      // Mostrar campo de contraseña
      showPassword: false,

      // Reglas de entrada
      rules: {
        required: (value) => (value && Boolean(value)) || "Required",
      },
    };
  },
  watch: {
    errorLoginLicen: function (val) {
      if (val) {
        this.isLoading = false;
      }
    },
  },
  methods: {
    // ...mapActions("auth-app", ["loginUser", "viewUser", "googleAuth"]),
    ...mapActions("auth-module", [
      "signInUserLicen",
      "getSedes",
      "setErrorLoginLicen",
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.setErrorLoginLicen();
        this.signInUserLicen({
          email: this.email,
          password: this.password,
          sucursal: this.sucursal,
        });
      }
    },
    btnSignIn() {
      this.signIn({ email: this.email, password: this.password });
    },
    signInProvider(provider) {},
    resetErrors() {
      this.error = false;
      this.errorMessages = "";

      this.errorProvider = false;
      this.errorProviderMessages = "";
    },
    signinGoogleAuth() {
      this.googleAuth();
    },
    verifyAuthClinica() {
      // console.log("revisando");
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      if (!clinicaUser) {
        router.push("/auth/login");
      }
    },
    getSedesAuth() {
      this.getSedes();
    },
  },
  computed: {
    ...mapState("auth-module", [
      "user",
      "errorPassLogin",
      "errorPassLoginMsg",
      "sedes",
      "errorLoginLicen",
    ]),
    getNameClinica() {
      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      return clinicaUser.Name;
    },
  },
  created() {
    this.verifyAuthClinica();
    this.getSedesAuth();
  },
};
</script>

<style>
/* Modificaciones a título */
h1 {
  color: black;
  font-size: 22px;
}

/* Modificaciones a contenedor principal */
.main_container {
  background: #fbfdff;
}

.cardContainer {
  width: 550px;
}

</style>
