<template >
  <div class="d-flex justify-center">
    <div v-if="this.$route.params.id">
      <v-card v-if="validData" class="ma-2 pa-3 rounded-xl" max-width="860px">
        <!-- Alerta -->
        <div>
          <v-card-title class="my-3 d-flex justify-center">
            <h3 class="text-center">Verificación de documento electrónico</h3>
          </v-card-title>
          <!-- contenido -->
          <v-card-text>
            <v-alert outlined color="primary" type="info"
              >Podrá ver el documento generado por el profesional, verifique que
              el contenido que ha recibido sea identico generado a
              continuación</v-alert
            >
            <v-row>
              <v-col class="mx-4">
                <div>Identifiación del documento: {{ doc.Id }}</div>
                <div>Fecha de emisión: {{ doc.Fecha_creacion }}</div>
                <div>
                  Emitido por <strong>{{ doc.Doctor_nombre }}</strong> RUN
                  {{ doc.Doctor_rut }}
                </div>
                <div>
                  Generado en {{ doc.Clinica }}, {{ doc.Clinica_sucursal }}
                </div>
              </v-col>
            </v-row>
            <!-- Contenido -->
            <div>
              <!-- Paciente -->
              <v-row class="mb-8" ref="atencionref">
                <!-- Propietario -->
                <v-col cols="12" xs="12" md="12" lg="6" xl="6">
                  <div class="pa-3">
                    <h2 class="ma-2 mb-5" ref="centralViewAtencion">
                      Datos Propietario
                    </h2>
                    <div>
                      <v-text-field
                        v-model="doc.Cliente_nombre"
                        readonly
                        outlined
                        label="Nombre"
                        dense
                        class="ma-2"
                      ></v-text-field>
                    </div>
                    <div>
                      <v-text-field
                        v-model="doc.Cliente_rut"
                        readonly
                        outlined
                        label="Rut"
                        dense
                        class="ma-2"
                      ></v-text-field>
                    </div>
                    <div
                      class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                    >
                      <v-text-field
                        v-model="doc.Cliente_direccion"
                        label="Dirección"
                        outlined
                        dense
                        readonly
                        class="ma-2"
                      >
                      </v-text-field>
                    </div>
                  </div>
                </v-col>
                <v-divider
                  vertical
                  class="my-8 d-none d-sm-none d-md-none d-lg-flex d-xl-flex"
                ></v-divider>
                <!-- Mascota -->
                <v-col cols="12" xs="12" md="12" lg="6" xl="6">
                  <div class="pa-3">
                    <h2 class="ma-2 mb-5">Datos Mascota</h2>
                    <div
                      class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                    >
                      <v-text-field
                        v-model="doc.Cliente_mascota"
                        outlined
                        readonly
                        label="Nombre"
                        dense
                        class="ma-2"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        label="N° de ficha"
                        v-model="doc.Cliente_ficha"
                        readonly
                        dense
                        class="ma-2"
                      >
                      </v-text-field>
                    </div>
                    <div
                      class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                    >
                      <v-text-field
                        outlined
                        dense
                        readonly
                        label="Edad"
                        :value="getAge(doc.Mascota_nacimiento)"
                        class="ma-2"
                      >
                      </v-text-field>
                      <v-text-field
                        outlined
                        dense
                        readonly
                        v-model="doc.Mascota_sexo"
                        label="Género"
                        class="ma-2"
                      ></v-text-field>
                    </div>
                    <div
                      class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                    >
                      <v-text-field
                        outlined
                        v-model="doc.Mascota_raza"
                        label="Raza"
                        readonly
                        dense
                        class="ma-2"
                      ></v-text-field>
                      <v-text-field
                        outlined
                        dense
                        v-model="doc.Mascota_especie"
                        label="Especie"
                        readonly
                        class="ma-2"
                      ></v-text-field>
                    </div>
                    <div
                      class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                    >
                      <v-text-field
                        outlined
                        dense
                        v-model="doc.Microchip"
                        label="N° de chip"
                        readonly
                        class="ma-2"
                      >
                      </v-text-field>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <!-- Prescripción receta-->
              <div v-if="doc.TipoDoc == 'receta'" class="mx-3">
                <!-- Línea divisoria -->
                <h2 class="text-center">Receta</h2>

                <strong>Prescripción</strong>
                  <p v-html="setDataReceta"></p>
                <p></p>
              </div>
              <!-- Prescripción receta-->
              <div v-if="doc.TipoDoc == 'certificado'" class="mx-3">
                <h2 class="text-center my-4">{{ doc.Certificado_titulo }}</h2>
                <!-- certificado hotal -->
                <div v-if="doc.Tipo_certificado == 'hotel'">
                  <!-- formulario -->
                  <v-row class="ma-0 pa-0">
                    <!-- seleccionde estadia -->
                    <v-col cols="2.5">
                      <h2>Estadía</h2>
                      <p>{{ doc.Certificado_hotel_estadia }}</p>
                    </v-col>
                    <v-divider vertical class="mt-6 mr-5 mb-6"></v-divider>
                    <!-- seleccion de transporte -->
                    <v-col cols="2.5">
                      <h2>Transporte</h2>
                      <p>{{ doc.Certificado_hotel_transporte }}</p>
                    </v-col>
                    <v-divider vertical class="mt-6 mr-5 mb-6"></v-divider>
                    <!-- selección de alimento -->
                    <v-col cols="2.5">
                      <h2>Alimento</h2>
                      <p>{{ doc.Certificado_hotel_alimento }}</p>
                    </v-col>
                    <v-divider vertical class="mt-6 mr-5 mb-6"></v-divider>
                    <!-- fecha de planifación -->
                    <v-col cols="4.5">
                      <h2>Planificación</h2>
                      <p>{{ doc.Certificado_hotel_ingreso }}</p>
                      <p>{{ doc.Certificado_hotel_salida }}</p>
                    </v-col>
                  </v-row>
                </div>
                <!-- certificado de vacuna -->
                <div
                  v-if="doc.Tipo_certificado == 'vacuna'"
                  class="text-center"
                >
                  <p>
                    {{ doc.Certificado_vacuna_nombre }} Aplicada el
                    {{ doc.Certificado_vacuna_fecha }}
                  </p>
                </div>
                <!-- certificado radiologocio -->
                <div v-if="doc.Tipo_certificado == 'radiologico'">
                  <!-- examen -->
                  <div>
                    <strong>Examen solicitado</strong>
                    <p>{{ doc.Certificado_radio_examen }}</p>
                  </div>
                  <!-- antecendete -->
                  <div>
                    <strong>Antecedenes</strong>
                    <p>{{ doc.Certificado_radio_antecedente }}</p>
                  </div>
                  <!-- radiodiagnostico -->
                  <div>
                    <strong>Radiodiagnostico</strong>
                    <p>{{ doc.Certificado_radio_interpretacion }}</p>
                  </div>
                  <!-- interpretacion -->
                  <div>
                    <strong>Interpretación</strong>
                    <p>{{ doc.Certificado_radio_radiodiagnostico }}</p>
                  </div>
                </div>
                <!-- Descripcion -->
                <div>
                  <strong>Descripción</strong>
                  <p v-html="setDataDescripcionCertificado"></p>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
      </v-card>
      <div v-else>
        <v-card elevation="0" class="mx-5" height="100vh" width="100%">
          <v-overlay absolute opacity="2" color="background">
            <v-progress-circular
              indeterminate
              :size="200"
              color="primary"
              class=""
            >
              Cargando</v-progress-circular
            >
          </v-overlay>
        </v-card>
      </div>
    </div>
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="mt-3 ml-3">No es posible mostrar esta vista</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      validData: false,
      doc: {
        Id: "",
        TipoDoc: "",
        Tipo_certificado: "",
        Cliente_nombre: "",
        Cliente_rut: "",
        Cliente_direccion: "",
        Cliente_comuna: "",
        Cliente_ciudad: "",
        Cliente_mascota: "",
        Cliente_ficha: 0,
        Mascota_nacimiento: "",
        Mascota_color: "",
        Microchip: "",
        Mascota_sexo: "",
        Mascota_raza: "",
        Mascota_especie: "",
        Doctor_nombre: "",
        Doctor_rut: "",
        Clinica: "",
        Clinica_sucursal: "",
        Prescripcion: "",
        Certificado_titulo: "",
        Certificado_descripcion: "",
        Certificado_hotel_ingreso: "",
        Certificado_hotel_salida: "",
        Certificado_hotel_estadia: "",
        Certificado_hotel_alimento: "",
        Certificado_hotel_transporte: "",
        Certificado_radio_examen: "",
        Certificado_radio_antecedente: "",
        Certificado_radio_interpretacion: "",
        Certificado_radio_radiodiagnostico: "",
        Certificado_vacuna_nombre: "",
        Certificado_vacuna_fecha: "",
        Fecha_creacion: "",
      },
    };
  },
  computed: {
    setDataDescripcionCertificado() {
      return this.doc.Certificado_descripcion.replace(
        /(?:\r\n|\r|\n)/g,
        "<br/>"
      );
    },
    setDataReceta() {
      return this.doc.Prescripcion.replace(
        /(?:\r\n|\r|\n)/g,
        "<br/>"
      );
    },
  },
  methods: {
    ...mapActions("medical-module", ["getDocumentById"]),
    getAge(dateString) {
      var now = new Date();
      var today = new Date(now.getYear(), now.getMonth(), now.getDate());

      var yearNow = now.getYear();
      var monthNow = now.getMonth();
      var dateNow = now.getDate();

      var dob = new Date(
        dateString.substring(0, 4),
        dateString.substring(5, 7) - 1,
        dateString.substring(8, 10)
      );

      var yearDob = dob.getYear();
      var monthDob = dob.getMonth();
      var dateDob = dob.getDate();
      var age = {};
      var ageString = "";
      var yearString = "";
      var monthString = "";
      var dayString = "";

      let yearAge = yearNow - yearDob;

      if (monthNow >= monthDob) var monthAge = monthNow - monthDob;
      else {
        yearAge--;
        var monthAge = 12 + monthNow - monthDob;
      }

      if (dateNow >= dateDob) var dateAge = dateNow - dateDob;
      else {
        monthAge--;
        var dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }

      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge,
      };

      if (age.years > 1) yearString = " años";
      else yearString = " año";
      if (age.months > 1) monthString = " meses";
      else monthString = " mes";
      if (age.days > 1) dayString = " días";
      else dayString = " día";

      if (age.years > 0 && age.months > 0 && age.days > 0)
        ageString =
          age.years +
          yearString +
          ", " +
          age.months +
          monthString +
          ", y " +
          age.days +
          dayString;
      else if (age.years == 0 && age.months == 0 && age.days > 0)
        ageString = "solo " + age.days + dayString;
      else if (age.years > 0 && age.months == 0 && age.days == 0)
        ageString = age.years + yearString + " Feliz cumpleaños!!";
      else if (age.years > 0 && age.months > 0 && age.days == 0)
        ageString = age.years + yearString + " y " + age.months + monthString;
      else if (age.years == 0 && age.months > 0 && age.days > 0)
        ageString =
          age.months + monthString + " y " + age.days + dayString + ".";
      else if (age.years > 0 && age.months == 0 && age.days > 0)
        ageString = age.years + yearString + " y " + age.days + dayString + ".";
      else if (age.years == 0 && age.months > 0 && age.days == 0)
        ageString = age.months + monthString + " .";
      else ageString = "Oops! No es posible calcular!";

      return ageString;
    },
    setDateNacimiento(nacimiento) {
      return nacimiento.substring(0, 10);
    },
    async loadEdocument() {
      if (this.$route.params.id) {
        this.doc = await this.getDocumentById(this.$route.params.id);
        if (this.doc.Id) {
          this.validData = true;
        }
      } else {
      }
    },
  },
  created() {
    this.loadEdocument();
  },
};
</script>
<style lang="">
</style>