<template>
  <!-- Título principal -->
  <div class="flex-grow-1">
    <div class="d-flex align-center mb-10 my-8">
      <v-col cols="1">
        <v-btn icon rounded @click="$router.go(-1)">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dense v-bind="attrs" v-on="on">
                <v-icon size="28">mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span>Volver</span>
          </v-tooltip>
        </v-btn>
      </v-col>
      <v-col cols="11" class="d-flex justify-center">
        <h1 class="justify-center">Cuenta</h1>
      </v-col>
    </div>
    <div
      class="
        pa-2 pa-md-4
        flex-grow-1
        align-center
        justify-center
        d-flex
        flex-column
      "
    >
      <!-- información básica -->
      <!-- <v-alert
        v-if="!userProfile.verified_email"
        width="100%"
        prominent
        border="left"
        colored-border
        type="warning"
        elevation="2"
      >
        <v-row align="center">
          <v-col class="grow">
            Se requiere la verificación de correo electrónico, llegará un correo
            a su bandeja, debes pinchar el enlace para verificar
          </v-col>
          <v-col class="shrink">
            <div class="d-flex">
              <v-btn color="primary" outlined class="primary--text mr-1"
                >Verificar</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert
        v-model="!userProfile.verified_phone"
        width="100%"
        prominent
        border="left"
        colored-border
        type="warning"
        elevation="2"
      >
        <v-row align="center">
          <v-col class="grow">
            Se requiere la verificación de número teléfonico, llegará un sms al
            número con el código de verificación para ingresar
          </v-col>
          <v-col class="shrink">
            <div class="d-flex">
              <v-btn color="primary" outlined class="primary--text mr-1" @click="verifiedPhone = true"
                >Verificar</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-alert> -->
      <v-row v-if="verifiedPhone" width="100%">
        <v-card-title>Ingresa el código enviado a tu teléfono</v-card-title>
        <v-card class="d-flex mb-2" width="100%">
          <v-col cols="2" class="my-2">
            <div class="ma-auto">
              {{ timerCount }}
              Seg
            </div>
          </v-col>
          <v-col cols="8">
            <div class="ma-auto" style="max-width: 300px">
              <v-otp-input v-model="otp" :length="length"></v-otp-input>
            </div>
          </v-col>

          <v-col cols="2" class="my-2">
            <v-btn color="primary" :disabled="!isActive">Verificar</v-btn>
          </v-col>
        </v-card>
      </v-row>
      <!-- Tarjeta de información personal -->
      <v-card class="personalInformationCard pa-3 mb-5 rounded-xl">
        <div class="mt-4 ml-10">
          <h2>Información básica</h2>
        </div>
        <v-divider class="mt-4"></v-divider>
        <v-card-text>
          <!-- Edición (renderización condicional) -->
          <row v-if="editInfoBasic">
            <form width="100%">
              <div class="d-flex justify-center">
                <!-- input nombre de la empresa-->
                <v-text-field
                  :error="error"
                  label="Nombre del negocio"
                  name="name"
                  outlined
                  class="mr-1"
                ></v-text-field>
              </div>
              <v-btn class="mr-4" color="primary" @click="saveProfileCompany"
                >Guardar</v-btn
              >
            </form>
          </row>
          <row
            v-if="!editInfoBasic"
            class="
              d-flex
              justify-space-between
              flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
            "
          >
            <v-col class="d-flex" width="50%">
              <!-- Avatar -->
              <div class="mr-1">
                <v-avatar color="primary" size="45">
                  <v-icon color="white">mdi-account-circle-outline</v-icon>
                </v-avatar>
              </div>
              <!-- Contenido -->
              <div class="d-flex flex-column justify-center ml-2 mt-1">
                <strong>Nombre y Apellido</strong>
                <p>{{ user.Nombre }}</p>
              </div>
            </v-col>
            <v-col class="d-flex" width="50%">
              <!-- Avatar -->
              <div class="mr-1">
                <v-avatar color="primary" size="45">
                  <v-icon color="white">mdi-at</v-icon>
                </v-avatar>
              </div>
              <!-- Contenido -->
              <div class="d-flex flex-column justify-center ml-2 mt-1">
                <strong>Correo electrónico</strong>
                <p>{{ user.Email }}</p>
              </div>
            </v-col>
          </row>
          <row v-if="!editInfoBasic" class="d-flex justify-space-between">
            <v-col class="d-flex" width="50%">
              <!-- Avatar -->
              <div class="mr-1">
                <v-avatar color="primary" size="45">
                  <v-icon color="white">mdi-domain</v-icon>
                </v-avatar>
              </div>
              <!-- Contenido -->
              <div class="d-flex flex-column justify-center ml-2 mt-1">
                <strong>Sucursal</strong>
                <p>{{ user.Sucursal }}</p>
              </div>
            </v-col>
          </row>
        </v-card-text>
      </v-card>
      <!-- Ajustes de la cuenta -->
      <v-card class="accountConfigCard pa-3 mb-5 my-8 rounded-xl">
        <div class="mt-4 ml-10">
          <h2>Ajustes de la cuenta</h2>
        </div>
        <v-divider class="mt-4"></v-divider>
        <!-- cambiar la contraseña -->
        <v-card-text class="px-4">
          <!-- <v-row>
            <v-col>
              <div class="d-flex">
                <div class="mt-2">
                  <v-icon color="primary" size="30">mdi-email-outline</v-icon>
                </div>
                <div class="ml-6">
                  <h3 class="text--black">Cambiar email</h3>
                  <p>Cuenta de usuario de ingreso de sesión</p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="2" class="d-flex justify-end">
              <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row> -->
          <!-- <v-divider> </v-divider> -->
          <div>
            <v-row class="mt-1">
              <v-col>
                <div class="d-flex">
                  <div class="mt-2">
                    <v-icon color="primary" size="30"
                      >mdi-account-key-outline</v-icon
                    >
                  </div>
                  <div class="ml-6">
                    <h3 class="text--black">Cambiar contraseña</h3>
                    <p>Cambiar la contraseña de acceso de usuario</p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" lg="2" class="d-flex justify-end">
                <v-btn icon @click="activeShowChangePassword = true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon dense v-bind="attrs" v-on="on">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <span>Modificar</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
            </v-row>
            <div v-if="activeShowChangePassword" class="mt-3">
              <v-text-field
                v-model="password"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Contraseña actual"
                name="password"
                outlined
                class="mb-3"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field
                v-model="new_password"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Nueva contraseña"
                name="password"
                outlined
                class="mb-3"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field
                v-model="repeat_password"
                dense
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Repita contraseña"
                name="password"
                outlined
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <div class="d-flex justify-end">
                <v-btn
                  color="primary"
                  class="ma-1 text-capitalize"
                  @click="btnChangePasswordLoginLicen"
                  :disabled="
                    password == '' ||
                    new_password == '' ||
                    repeat_password == ''
                  "
                  :loading="activeLoadingChangePasswordLoginLicen"
                  >Cambiar contraseña</v-btn
                >
                <v-btn
                  color="primary"
                  outlined
                  class="ma-1 text-capitalize"
                  @click="activeShowChangePassword = false"
                  >Cerrar</v-btn
                >
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider class="mt-4"></v-divider>
        <!-- crear la firma -->
        <v-card-text class="px-4">
          <!-- <v-row>
            <v-col>
              <div class="d-flex">
                <div class="mt-2">
                  <v-icon color="primary" size="30">mdi-email-outline</v-icon>
                </div>
                <div class="ml-6">
                  <h3 class="text--black">Cambiar email</h3>
                  <p>Cuenta de usuario de ingreso de sesión</p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="2" class="d-flex justify-end">
              <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row> -->
          <!-- <v-divider> </v-divider> -->
          <div>
            <v-row class="mt-1">
              <v-col>
                <div class="d-flex">
                  <div class="mt-2">
                    <v-icon color="primary" size="30">mdi-draw</v-icon>
                  </div>
                  <div class="ml-6 ">
                    <!-- titulo y carga -->
                    <div class="d-flex">
                      <h3 class="text--black">Crear firma</h3>
                      <v-chip
                        color="primary"
                        class="mx-2 pa-2"
                        v-if="licen.Firma != 'n'"
                        small
                        >Creada</v-chip
                      >
                    </div>
                    <p>
                      Crear firma electrónica para la emisión de recetas y
                      certificados
                    </p>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" lg="2" class="d-flex justify-end">
                <v-btn icon @click="activeShowCreateESign = true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon dense v-bind="attrs" v-on="on">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </template>
                    <span>Modificar</span>
                  </v-tooltip>
                </v-btn>
              </v-col>
            </v-row>
            <div v-if="activeShowCreateESign" class="my-3">
              <p class="pa-0 ma-0 mb-3 text-center">
                Dibuje la firma dentro del recuadro
              </p>
              <v-img :src="licen.Firma"></v-img>
              <div class="sign d-flex justify-center">
                <VueSignaturePad
                  class="signature-box"
                  width="300px"
                  height="200px"
                  ref="signaturePad"
                />
              </div>
              <div class="d-flex justify-end my-3">
                <v-btn
                  color="primary"
                  class="mr-2 text-capitalize rounded-lg"
                  @click="save"
                  >Crear</v-btn
                >
                <v-btn
                  color="primary"
                  outlined
                  class="text-capitalize rounded-lg"
                  @click="undo"
                  >Deshacer</v-btn
                >
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>

      <!-- Ajustes del negocio -->
      <v-card class="businessConfigCard pa-3 mb-5 my-8 rounded-xl">
        <div class="mt-4 ml-10">
          <h2>Ajustes del negocio</h2>
        </div>
        <v-divider class="mt-4"></v-divider>
        <v-card-text class="px-4">
          <!-- logo -->
          <v-row class="mt-1">
            <v-col>
              <div class="d-flex">
                <div class="mt-2">
                  <v-icon color="primary" size="30"
                    >mdi-file-image-plus-outline</v-icon
                  >
                </div>
                <div class="ml-6">
                  <div class="d-flex">
                      <h3 class="text--black">Logo</h3>
                      <v-chip
                        color="primary"
                        class="mx-2 pa-2"
                        v-if="logoUpload"
                        small
                        >Cargado</v-chip
                      >
                    </div>
                  <p>Es visible para la emisión de documentos</p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="2" class="d-flex justify-end">
              <v-btn icon @click="activeShowUploadLogo = true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon dense v-bind="attrs" v-on="on">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </template>
                  <span>Modificar</span>
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
          <div
            v-if="activeShowUploadLogo"
            class="
              mb-2
              d-flex
              flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row
              justify-center
              align-center
            "
          >
            <div>
              <v-avatar
                v-if="logoUpload"
                tile
                size="100"
                class="d-flex flex-column ma-1"
              >
                <img class="logo" height="50px" :src="logoUpload" />
              </v-avatar>

              <v-avatar v-else tile size="100" class="d-flex flex-column ma-1">
                <v-icon size="80">mdi-image-outline</v-icon>
              </v-avatar>
            </div>
            <div class="d-flex mt-8 ml-4 align-center">
              <v-file-input
                accept="image/*"
                label="Agrergar imange a la variante"
                outlined
                counter
                v-model="logosrc"
                prepend-icon="mdi-file-image-outline"
                :show-size="1000"
                class="border-edit ma-1"
                height="100%"
              >
                <template v-slot:selection="{ text }">
                  <v-avatar tile size="200" class="d-flex flex-column ma-1">
                    <img v-if="logosrc" :src="getUrlVariant()" />
                    <v-chip color="primary" dark label small>
                      {{ text }}
                    </v-chip>
                  </v-avatar>
                </template>
              </v-file-input>
              <v-btn color="primary" class="ml-2" @click="btnUploadLogo"
                >Cargar</v-btn
              >
            </div>
          </div>
          <div class="d-flex align-center"></div>
          <v-row class="mt-3">
            <v-col>
              <div class="d-flex">
                <div class="mt-2">
                  <v-icon color="primary" size="30"
                    >mdi-email-fast-outline</v-icon
                  >
                </div>
                <div class="ml-6">
                  <h3 class="text--black">Correo de salida</h3>
                  <p>
                    Configuración SMTP para envío de correos de origen
                    personalizado
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" lg="2" class="d-flex justify-end">
              <!-- <v-btn icon>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn> -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- información de facturación -->
    </div>
    <v-snackbar color="primary" v-model="successChangePasswordLoginLicen">
      Clave cambiada con éxito
    </v-snackbar>
    <v-snackbar color="primary" v-model="successCreateEsign">
      Firma creada con éxito
    </v-snackbar>
    <v-snackbar color="red darken-3" v-model="failChangePasswordLoginLicen">
      Error al cambiar la clave
    </v-snackbar>

    <div v-if="activeUploadFileOver">
      <v-overlay :absolute="absolute" opacity="0">
        <v-progress-circular
          style="z-index: 20001"
          indeterminate
          :size="200"
          color="primary"
          class=""
          title="Imagen"
        >
          Subiendo</v-progress-circular
        >
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      minutes: 0,
      secondes: 0,
      verifiedPhone: false,
      otp: "",
      length: 7,
      showPassword: false,
      editInfoBasic: false,
      textEditInfoBasic: "Editar",
      timerCount: 240,
      password: "",
      new_password: "",
      repeat_password: "",
      activeShowChangePassword: false,
      activeLoadingChangePasswordLoginLicen: false,
      activeShowUploadLogo: false,
      logo: [],
      activeUploadFileOver: false,
      activeShowCreateESign: false,
      uri: "",
      logosrc: "",
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
    successChangePasswordLoginLicen: function (val) {
      this.activeLoadingChangePasswordLoginLicen = false;
      this.activeShowChangePassword = false;
      this.password = "";
      this.new_password = "";
      this.repeat_password = "";
    },
    failChangePasswordLoginLicen: function (val) {
      this.activeLoadingChangePasswordLoginLicen = false;
    },
  },
  methods: {
    ...mapActions("auth-module", [
      "postUpdatePasswordLoginLicen",
      "getDownloadLogo",
    ]),
    ...mapActions("auth-module", ["postEsignByEmail"]),
    ...mapActions("medical-module", ["postLogoStorage"]),
    activeEditInfoBasic() {
      if (this.editInfoBasic == false) {
        this.editInfoBasic = true;
        this.textEditInfoBasic = "Cerrar";
      } else {
        this.editInfoBasic = false;
        this.textEditInfoBasic = "Editar";
      }
    },
    saveProfileCompany() {
      let data = {
        profile: this.profileCompany,
        access: this.userInfo.access,
      };
    },
    btnChangePasswordLoginLicen() {
      this.activeLoadingChangePasswordLoginLicen = true;
      let changePasswordLoginLicen = {
        email: this.user.Email,
        passusuario: this.password,
        sucursal: this.user.Sucursal,
        NewPassusuario: this.repeat_password,
      };
      this.postUpdatePasswordLoginLicen(changePasswordLoginLicen);
    },
    uploadFileExamen() {
      this.logo = this.$refs.logoref.files[0];
    },
    submitFileExamen() {
      this.activeUploadFileOver = true;

      let clinicaUser = JSON.parse(localStorage.getItem("clinicaUser"));
      const formData = new FormData();
      formData.append("file", this.logo);
      const headers = { "Content-Type": "multipart/form-data" };
      axios
        .post(
          `https://service.clivenapp.cl/upload-logo?token=${clinicaUser.Token}`,
          formData,
          { headers }
        )
        .then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
          this.activeUploadFileOver = false;
          this.getDownloadLogo();
        });
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    cleanSign() {
      this.$refs.signaturePad.remove();
    },
    save() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      var image = new Image();
      image.src = data;
      this.uri = data;
      this.postEsignByEmail({
        esign: { signature: this.uri, email: this.user.Email },
        sucursal: this.user.Sucursal,
      });
    },
    getUrlVariant() {
      let uri = URL.createObjectURL(this.logosrc);
      return uri;
    },
    btnUploadLogo() {
      this.postLogoStorage({ logosrc: this.logosrc });
    },
  },
  computed: {
    ...mapState("auth-module", [
      "user",
      "successChangePasswordLoginLicen",
      "failChangePasswordLoginLicen",
      "logoUpload",
      "licen",
      "successCreateEsign",
    ]),
    ...mapState("medical-module", ["doctors"]),
    isActive() {
      return this.otp.length === this.length;
    },
    getUrl() {
      let uri = URL.createObjectURL(this.logo);
      return uri;
    },
  },
};
</script>
<style>
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Seleccione archivo";
  display: flex;
  border: 1.5px solid #003186;
  border-radius: 3px;
  border-color: "red";
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  color: #003186;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border: 2px solid #003186;
}
.signature-box {
  border-width: 0.1;
  border-style: solid;
  border-color: #003186;
  border-radius: 20px;
}
.signcreated {
  object-fit: contain;
  margin-top: 15px;
  margin-left: 30px;
}

/* RWD ancho de las tarjetas contenedoras */
@media only screen and (max-width: 900px) {
  .personalInformationCard {
    width: 100%;
  }
  .accountConfigCard {
    width: 100%;
  }
  .businessConfigCard {
    width: 100%;
  }
}
@media only screen and (min-width: 900px) {
  .personalInformationCard {
    width: 70%;
  }
  .accountConfigCard {
    width: 70%;
  }
  .businessConfigCard {
    width: 70%;
  }
}
</style>
