<template>
  <div>
    <!-- Título principal -->
    <div class="d-flex align-center justify-space-between mb-10 my-8">
      <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn>
      <h1 class="justify-center">Comentarios de la app</h1>
      <div></div>
    </div>
    <!-- Información respecto al uso de la página -->
    <v-alert
      colored-border
      type="info"
      color="primary"
      elevation="2"
      class="my-8"
    >
      Gracias por utilizar nuestra aplicación. <br />
      Para nosotros tus comentarios son muy valiosos, por favor compártenos
      todas tus dudas, sugerencias y experiencias. Además, puedes reportar
      errores en el funcionamiento y acciones.
      <br />
      Muchas gracias.
    </v-alert>
    <v-card width="100%" class="pa-3 mb-5 rounded-xl">
      <v-card-title class="mt-4 ml-4">
        <h2>Crear un nuevo comentario para la aplicación</h2>
      </v-card-title>
      <v-spacer></v-spacer>
      <div class="pa-3">
        <v-row class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row mt-5">
          <v-col class="align-center">
            <div>
              <div>
                <h3 class="black--text mr-3 mb-3">Seleccione tipo</h3>
              </div>
              <v-select
                v-model="selectedTypeFeedback"
                outlined
                label="Seleccione"
                :items="typesFeedback"
              ></v-select>
            </div>
          </v-col>
          <v-col class="mt-5">
            <div class="msg_experience pl-2" 
              >Deslice según su percepción de experiencia con la app,
              donde 1 es deficiente y 10 una muy buena experiencia</div
            >
            <v-slider v-model="slider" :thumb-size="40" thumb-label="always">
              <template v-slot:thumb-label="{ value }">
                {{ satisfactionEmojis[Math.min(Math.floor(value / 10), 9)] }}
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row
          class="pa-0 ma-0"
          v-if="selectedTypeFeedback == 'Reportar error'"
        >
          <v-col>
            <div class="d-flex">
              <div>
                <h3 class="black--text mt-2 mr-2">Tipo de error</h3>
              </div>
              <v-select
                dense
                outlined
                v-model="selectedTypeError"
                label="Seleccione"
                :items="typeError"
              ></v-select>
            </div>
          </v-col>
          <v-col></v-col>
        </v-row>
        <div class="d-flex flex-column">
          <div>
            <h3 class="black--text mt-3 mb-3">
              Seleccione vista relacionada (Opcional)
            </h3>
          </div>
          <v-autocomplete
            v-model="selectView"
            :items="menuView"
            item-text="name"
            item-value="name"
            itemscope
            flat
            outlined
            append-icon="mdi-magnify"
            label="Buscar en las vistas de la app"
            @change="click(item)"
          ></v-autocomplete>
        </div>
        <v-row class="pa-3 mt-5 d-flex flex-column mb-2">
          <v-textarea
            v-model="feedback"
            outlined
            label="Ingrese su comentario sobre la app"
          ></v-textarea>
          <div class="d-flex justify-end">
            <v-btn :loading="activeLoadingCreateFeedback" color="primary" class="text-capitalize rounded-lg" @click="btnCreateFeedback"
              >Enviar comentario</v-btn
            >
          </div>
        </v-row>
      </div>
    </v-card>
    <v-snackbar
      color="primary"
      v-model="successCreateFeedback"
      multiLine
    >
      Feedback creado con éxito
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      selectedTypeFeedback: "",
      selectedTypeError: "",
      feedback: "",
      typesFeedback: [
        "Sugerencia",
        "Reclamo",
        "Felicitación",
        "Reportar error",
        "Otro",
      ],
      typeError: [
        "Problemas con interfaz",
        "Problemas de usabilidad",
        "Problemas con una acción",
        "Problemas con datos",
        "Problema de seguridad",
        "Otro problema",
      ],
      satisfactionEmojis: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      slider: 100,
      selectView: "",
      menuView: [
        {
          name: "Clientes",
          link: "/customers",
        },
        {
          name: "Especies y razas",
          link: "/pets/types",
        },
        {
          name: "Especialidades",
          link: "/medical/specialties",
        },
        {
          name: "Doctores y personal",
          link: "/medical/professionals",
        },
        {
          name: "Visitas",
          link: "/medical/visits",
        },
        {
          name: "Ficha médica",
          link: "/medical/records",
        },
        {
          name: "Mi cuenta",
          link: "/mi/records",
        },
      ],
      activeLoadingCreateFeedback: false
    };
  },
  watch: {
    successCreateFeedback: function (val) {
      if (val) {
        this.selectedTypeFeedback = "";
        this.selectedTypeError = "";
        this.selectView = "";
        this.feedback = "";
        this.slider = 100;
        this.activeLoadingCreateFeedback = false
      }
    },
  },
  computed: {
    ...mapState("feedbacks-module", ["successCreateFeedback"]),
    ...mapState("auth-module", ["user", "clinicaUser"]),
  },
  methods: {
    ...mapActions("feedbacks-module", ["postFeedback"]),
    async btnCreateFeedback() {
      this.activeLoadingCreateFeedback = true
      let now = new Date().toISOString();
      let clinicaUser = await JSON.parse(localStorage.getItem("clinicaUser"));
      let feedback = {
        type: this.selectedTypeFeedback,
        type_error: this.selectedTypeError,
        select_view: this.selectView,
        feedback: this.feedback,
        rating: this.slider.toString(),
        user_name: this.user.Nombre,
        user_email: this.user.Email,
        user_clinic: clinicaUser.Name,
        file: "",
        response_team: "",
        date_created: now.substring(0, 10) + " " + now.substring(11, 16),
      };
      this.postFeedback({ feedback: feedback });
    },
  },
};
</script>
<style>
@media only screen and (min-width: 400px) {
  .msg_experience {
    padding-bottom: 10px;
  }
}
</style>
