<template>
  <div>
    <!-- Título principal -->
    <div class="d-flex align-center mb-10 my-8">
      <v-col cols="12" class="d-flex justify-center">
        <h1 class="justify-center">Inicio</h1>
      </v-col>
    </div>
    <!-- alerta de bienvenida -->
    <v-alert
      color="primary"
      dark
      icon="mdi-information-outline"
      border="left"
      class="mt-4"
      prominent
    >
      Bienvenido a ClivenApp Cloud, contará con una experiencia guiada por la
      aplicación con este tipo de alertas, así conocerá todas las acciones y
      funciones que puede realizar. Además de las capacidades y alcances dentro
      de la aplicación. Junto a esto, podrá ver eventos y notificaciones que
      ocurren dentro de la app. <br />
      Para mayor información y ayuda, contamos con soporte en tiempo real.
    </v-alert>
    <v-dialog v-model="profileNotFound" persistent max-width="450">
      <v-card>
        <v-card-title class="text-h5">
          Perfil de doctor no encontrado
        </v-card-title>
        <v-card-text
          >No se encuentra un registro de doctor asociado a esta cuenta de
          usuario, estó genera un error en las acciones del sistema, debe
          contactar al administrador para solucionar esté problema</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="btnProcessLogOut"> Salir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Tarjeta de estado -->
    <!-- <div class="d-flex">
      <v-col v-for="(stats, index) in cardStats" :key="index">
        <v-card height="150px" class="ma-3 pa-3 d-flex flex-column align-center justify-center" rounded="">
          <h2 class="text-center">{{ stats.name }}</h2>
          <div class="display-2 mt-4">{{ stats.value }}</div>
        </v-card>

      </v-col>
    </div> -->
    <v-card class="rounded-xl pa-8 mt-4" elevation="1">
      <h2 class="my-3">Servicios programados</h2>
      <v-sheet tile height="54" class="d-flex">
        <div class="d-flex">
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-select
            v-model="type"
            :items="types"
            dense
            item-text="text"
            item-value="value"
            outlined
            hide-details
            class="ma-2"
            label="Seleccione tiempo"
          ></v-select>
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-sheet>
      <v-sheet height="600" class="mt-5">
        <v-calendar
          ref="calendar"
          v-model="value"
          locale="es-ES"
          :weekdays="weekday"
          :type="type"
          :events="events"
          @click:event="showEvent"
          :event-overlap-mode="mode"
          @click:more="viewDay"
          @click:date="viewDay"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="selectedEvent.bodydata">
              <span v-html="selectedEvent.details"></span>

              <div>
                Mascota: {{ selectedEvent.bodydata.Mascota }} - Ficha
                {{ selectedEvent.bodydata.Ficha }}
              </div>
              <div>Propietario: {{ selectedEvent.bodydata.Propietario }}</div>
              <div>Programado por: {{ selectedEvent.bodydata.Quien }}</div>
              <div>
                Observaciones: {{ selectedEvent.bodydata.Observaciones }}
              </div>
              <div>Estado: {{ selectedEvent.bodydata.Tareacumplida }}</div>
              <div>Sucursal: {{ selectedEvent.bodydata.Sucursal }}</div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      type: "month",
      focus: "",
      types: [
        { text: "Mes", value: "month" },
        { text: "Día", value: "day" },
        { text: "Semana", value: "week" },
        { text: "Próximos 4 días", value: "4day" },
      ],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      events: [],
      colors: ["indigo lighten-2", "indigo lighten-1"],
      names: [
        "Consulta",
        "Procedimiento",
        "Control",
        "Vacunación",
        "Peluqueria",
        "Cirugia",
      ],
      cardStats: [
        { name: "Atenciones del día", value: 40 },
        { name: "Esperando atencion", value: 2 },
        { name: "Procedimiento para hoy", value: 3 },
        { name: "En hotel", value: 2 },
      ],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  watch: {
    servis: function (val) {
      const events = [];
      val.forEach((element) => {
        let min = new Date(
          `${element.Fecha.substring(0, 10)}T${element.Hora.substring(11, 19)}`
        );
   
        let max = new Date(
          `${element.Fecha.substring(0, 10)}T${element.Hora.substring(11, 19)}`
        );
        
        max.setMinutes(min.getMinutes() + 15);
       

        events.push({
          name: element.Descripcion,
          start: min,
          end: max,
          color: "primary",
          timed: true,
          bodydata: element,
        });
      });
      this.events = events;
    },
  },
  computed: {
    ...mapState("auth-module", ["profileNotFound"]),
    ...mapState("medical-module", ["servis"]),
  },
  methods: {
    ...mapActions("auth-module", ["logOut"]),
    btnProcessLogOut() {
      this.logOut();
    },
    getEvents({ start, end }) {
      // const events = [];
      // const min = new Date(`${start.date}T00:00:00`);
      // const max = new Date(`${end.date}T23:59:59`);
      // const days = (max.getTime() - min.getTime()) / 86400000;
      // const eventCount = this.rnd(days, days + 20);
      // for (let i = 0; i < eventCount; i++) {
      //   const allDay = this.rnd(0, 3) === 0;
      //   const firstTimestamp = this.rnd(min.getTime(), max.getTime());
      //   const first = new Date(firstTimestamp - (firstTimestamp % 900000));
      //   const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
      //   const second = new Date(first.getTime() + secondTimestamp);
      //   events.push({
      //     name: this.names[this.rnd(0, this.names.length - 1)],
      //     start: first,
      //     end: second,
      //     color: this.colors[this.rnd(0, this.colors.length - 1)],
      //     timed: !allDay,
      //   });
      // }
      // this.events = events;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getEventColor(event) {
      return event.color;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    loadCalendar() {
      if (this.servis.length > 0) {
        const events = [];
        this.servis.forEach((element) => {
          let min = new Date(
            `${element.Quefecha.substring(0, 10)}T${element.Hora.substring(
              11,
              19
            )}`
          );
    
          let max = new Date(
            `${element.Quefecha.substring(0, 10)}T${element.Hora.substring(
              11,
              19
            )}`
          );
          
          max.setMinutes(min.getMinutes() + 15);
    

          events.push({
            name: element.Descripcion,
            start: min,
            end: max,
            color: "primary",
            timed: true,
            bodydata: element,
          });
        });
        this.events = events;
      }
    },
  },
  created() {
    this.loadCalendar();
  },
};
</script>
<style>
h1 {
  font-family: "Ubuntu", sans-serif;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 2px;
}
</style>
