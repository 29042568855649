<template>
  <div
  class="w-full d-flex align-center justify-center main_container"
  >
    <v-card
      class="cardContainer pa-3 rounded-xl mx-3"
    >
      <div class="pl-5 pr-5">
        <v-card-title class="d-flex flex-column justify-center mb-4">
          <v-avatar tile size="150">
            <v-img contain src="/clivenlogo.svg"></v-img>
          </v-avatar>
          <h1>
            Hola, bienvenido a
            <h1 class="d-flex justify-center">ClivenApp</h1>
          </h1></v-card-title
        >

        <v-card-subtitle class="mt-3 mb-5 d-flex justify-center"
          >Ingrese la cuenta de la clínica</v-card-subtitle
        >
        <!-- Formulario de ingreso -->
        <v-card-text>
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-text-field
              v-model="email"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              label="Correo electrónico"
              name="email"
              outlined
              class="mb-2"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>

            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPassword ? 'text' : 'password'"
              :error="error"
              :error-messages="errorMessages"
              label="Contraseña"
              name="password"
              outlined
              class="mb-3"
              @change="resetErrors"
              @keyup.enter="submit"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-alert
              v-if="errorLoginUser"
              class="ma-0 pa-2"
              type="error"
              border="left"
              >Error de credenciales</v-alert
            >
            <v-btn
              :loading="isLoading"
              :disabled="isSignInDisabled"
              block
              class="text-capitalize rounded-lg"
              x-large
              color="primary mt-8"
              @click="submit()"
              >Ingresar</v-btn
            >
          </v-form>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// Componente de iniciar sesión
export default {
  data() {
    return {
      // Botones de ingreso
      isLoading: false,
      isSignInDisabled: false,

      // Formulario
      isFormValid: true,
      email: "",
      password: "",

      // Error de formulario
      error: false,
      errorMessages: "",

      errorProvider: false,
      errorProviderMessages: "",

      // Mostrar entrada de contraseña
      showPassword: false,
      google: {
        id: "google",
        label: "Google",
        isLoading: false,
        methods: "googleAuth()",
      },

      // Reglas de entrada
      rules: {
        required: (value) => (value && Boolean(value)) || "Campo requerido",
      },
    };
  },
  methods: {
    // ...mapActions("auth-app", ["loginUser", "viewUser", "googleAuth"]),
    ...mapActions("auth-module", ["signInClinica", "setErrorLoginUser"]),
    submit() {
      if (this.$refs.form.validate()) {
        this.setErrorLoginUser();
        this.isLoading = true;
        this.signInClinica({ email: this.email, password: this.password });
      }
    },
    btnSignIn() {
      this.signInClinica({ email: this.email, password: this.password });
    },
    signInProvider(provider) {},
    resetErrors() {
      this.error = false;
      this.errorMessages = "";

      this.errorProvider = false;
      this.errorProviderMessages = "";
    },
    signinGoogleAuth() {
      this.googleAuth();
    },
  },
  watch: {
    errorLoginUser: function (val) {
      if (val) {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapState("auth-module", [
      "user",
      "errorPassLogin",
      "errorPassLoginMsg",
      "errorLoginUser",
    ]),
  },
};
</script>

<style>
/* Modificaciones a título */
h1 {
  color: black;
  font-size: 22px;
}

/* Modificaciones a contenedor principal */
.main_container {
  background: #fbfdff;
}

.cardContainer {
  width: 550px;
}


</style>
